import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { rhythm } from "../utils/typography"

interface IProps {
  title: string
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(24)};
  padding: ${rhythm(1.5)} ${rhythm(0.75)};
`

const Title = styled.h1`
  margin-bottom: ${rhythm(1.5)};
  margin-top: 0;
`

// https://www.styled-components.com/docs/advanced#caveat
const TitleLink = styled(Link)`
  box-shadow: none;
  color: inherit;
  text-decoration: none;
`

class Layout extends React.Component<IProps, any> {
  public render() {
    const { title, children } = this.props

    return (
      <Container>
        <header>
          <Title>
            <TitleLink to="/">{title}</TitleLink>
          </Title>
        </header>
        <main>{children}</main>
        <footer>© {new Date().getFullYear()} Takeshi Ohishi</footer>
      </Container>
    )
  }
}

export default Layout
